import { Component, OnInit } from '@angular/core';
import { EntidadService } from 'src/app/services/entidad.service';
import { Router } from '@angular/router';
import { URL_SERVICIOS } from 'src/app/config/config';
import { animationFrameScheduler } from 'rxjs';
import { FormatTimePipe, HeaderComponent } from 'src/app/shared/header/header.component';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styles: []
})
export class PaymentComponent implements OnInit {

  detalle: any;
  nombre: string;
  url = URL_SERVICIOS;
  errors: string = null;
  pagado: boolean = true;
  pagadoCero: boolean = false;

  constructor(
    private entidadService: EntidadService,
    private router: Router
  ) {
    FormatTimePipe.estado_session_pago=false;
    HeaderComponent.time_status_= true;
    if (this.entidadService.usuario) {
      const nombreCompleto = this.entidadService.usuario.name.split(' ');
      this.nombre = nombreCompleto[0];
    }

    if (this.entidadService.reservacion.pagado === '1') {
      this.pagado = true;
      this.pagadoCero=true;
      const filename  = this.entidadService.reservacion.filenamepdf;
      const token     = localStorage.getItem('tokenPF');
      const folder    = 'comprobantes';

      const filePath  = '/content/uploads?us=' + token +
                        '&tipFolder=' + folder +
                        '&archivo=' + filename;

      this.detalle = {
        paciente: this.entidadService.usuario.apellidos + ', ' + this.entidadService.usuario.name,
        sede: this.entidadService.reservacion.nombresede,
        medico: this.entidadService.reservacion.hora.nombre,
        fecha: this.entidadService.reservacion.hora.inicioms,
        hora: this.entidadService.reservacion.hora.inicioms,
        urlPdf: this.url + filePath,
        sexo:  this.entidadService.reservacion.sexodoctor
      };
    }
    else if (this.entidadService.reservacion.pagado === '2') {
      this.pagado = false;
      const urlCIP  = this.entidadService.reservacion.urlCip;
      const CIP  = this.entidadService.reservacion.cip;
      const token     = localStorage.getItem('tokenPF');

      this.detalle = {
        paciente: this.entidadService.usuario.apellidos + ', ' + this.entidadService.usuario.name,
        sede: this.entidadService.reservacion.nombresede,
        medico: this.entidadService.reservacion.hora.nombre,
        fecha: this.entidadService.reservacion.hora.inicioms,
        hora: this.entidadService.reservacion.hora.inicioms,
        urlPdf: urlCIP,
        codigoCIP: CIP,
        precio:((this.entidadService.reservacion.izipay.precio) / 100 ).toFixed(2)
      };
    }
    else if (this.entidadService.reservacion.pagado === '3') {
      this.pagado = true;
      this.pagadoCero = true;

      this.detalle = {
        paciente: this.entidadService.usuario.apellidos + ', ' + this.entidadService.usuario.name,
        sede: this.entidadService.reservacion.nombresede,
        medico: this.entidadService.reservacion.hora.nombre,
        fecha: this.entidadService.reservacion.hora.inicioms,
        hora: this.entidadService.reservacion.hora.inicioms
      };
    }

  }

  ngOnInit() {
    if (this.entidadService.estaLogueado()) {
      if (this.entidadService.reservacion.pagado !== '1' && this.entidadService.reservacion.pagado !== '2' && this.entidadService.reservacion.pagado !== '3') {
        this.router.navigate(['/seleccione-sede']);
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  openUrl(url: string) {

    this.entidadService.logout()
      .subscribe(() => {
        window.open(url, '_parent');
      }, error => {
        console.log("Error")
        setTimeout(() => {
          this.errors = 'Error';
        }, 5000);
      });
  }

}
