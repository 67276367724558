import { Component, OnInit, NgZone } from '@angular/core';
import { CitamedicaService } from 'src/app/services/citamedica.service';
import { SedeService } from 'src/app/services/sede.service';
import { EntidadService } from 'src/app/services/entidad.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormControlName } from '@angular/forms';
import { Utils } from 'src/app/services/utils';
import { NgxSpinnerService } from 'ngx-spinner';

declare function init_appointment();
declare var $: any;

@Component({
  selector: 'app-seguro',
  templateUrl: './seguro.component.html',
  styles: []
})
export class SeguroComponent implements OnInit {

  detalleForm: FormGroup;
  copagoCero = false;

  formSeguro = false;
  formSiSeguro = false;
  MedioPago = false;
  public static CostoCero: boolean = true;
  detallecitas: any = [];

  nombre: string;
  turnos: any[] = [];
  loadTurnos = false;
  today: number = Date.now();
  errors: string = null;
  detalle: any;
  detallereserva: any;
  detalledatos: any;
  pagado: any;

  seguros: Array<any> = [
    {
      '1-20001': 'EPS', '2-40007': 'Seguros',
      '27-20029': 'EPS', '6-40005': 'Seguros',
      '9-20002': 'EPS', '10-40004': 'Seguros',
      '14-20004': 'EPS', '15-40006': 'Seguros',
      '19-20005': 'EPS'
    }
  ];

  constructor(
    private entidadService: EntidadService,
    private citamedicaService: CitamedicaService,
    private sedeService: SedeService,
    private router: Router,
    private utils: Utils,
    private ngZone: NgZone,
    private spinner: NgxSpinnerService
  ) {
    init_appointment();
    this.formSeguro = true;
    this.formSiSeguro = false;

    this.entidadService.reservacion.izipayButton2 = undefined;
    this.entidadService.saveReservacion();
    if (this.entidadService.reservacion.cita2 == 0) {
      this.entidadService.reservacion.cita2 = 1;
      this.entidadService.saveReservacion();
      window.location.reload();
    }

    if (this.entidadService.reservacion.pagado) {
      this.router.navigate(['/']);
    }

    if (this.entidadService.usuario && this.entidadService.reservacion.hora) {
      var planseguro = this.entidadService.reservacion.seguroplan.split('-');

      const nombreCompleto = this.entidadService.usuario.name.split(' ');
      this.nombre = nombreCompleto[0];
      // console.log("MONTO",parseInt(this.entidadService.reservacion.cobertura.impCopagoFijo)*100)
      // console.log("this.entidadService.reservacion.seguroplan",this.entidadService.reservacion.seguroplan)
      this.entidadService.reservacion.izipay.precio = parseInt(this.entidadService.reservacion.cobertura.impCopagoFijo) * 100;
      this.entidadService.saveReservacion();
      this.detalle = {
        paciente: this.entidadService.usuario.apellidos + ', ' + this.entidadService.usuario.name,
        sede: this.entidadService.reservacion.nombresede,
        medico: this.entidadService.reservacion.hora.nombre,
        idmedico: this.entidadService.reservacion.hora.idmedico,
        fecha: this.entidadService.reservacion.hora.inicioms,
        hora: this.entidadService.reservacion.hora.inicioms,
        precioDet: this.entidadService.reservacion.cobertura.impCopagoFijo,
        seguro: this.entidadService.reservacion.seguro,
        seguroplan: this.entidadService.reservacion.seguroplan,
        beneficio: this.entidadService.reservacion.hora.inicioms,
        copagofijo: this.entidadService.reservacion.cobertura.impCopagoFijo,
        copagovariable: this.entidadService.reservacion.deducible,
        idpaciente: this.entidadService.usuario.id,
        poliza:this.entidadService.reservacion.poliza,
        codasegurado:this.entidadService.reservacion.codasegurado,
        codbeneficio: this.entidadService.reservacion.codbeneficio,
        idaseguradoraplan: parseInt(planseguro[0]),
        sedeipress: this.entidadService.reservacion.sedeipress,
        sexo: this.entidadService.reservacion.sexodoctor
      };
      // console.log("Detalle",this.detalle);
      if (this.entidadService.reservacion.cobertura.impCopagoFijo == 0) {
        SeguroComponent.CostoCero = false;
        this.router.navigate(['/']);
      }

      // console.log(this.detalle)
    }


  }

  get f() {

    return this.detalleForm.controls;

  }
  condicionales: any[] = [
    { idcondicional: "", abreviatura: 'Seleccione' },
    { idcondicional: 1, abreviatura: 'No' },
    { idcondicional: 2, abreviatura: 'Si' }
  ];


  error_form() {
    $('#alert').empty();
    $('#alert').css('display', 'block')
    var error_form = $('#error_form').val();
    $('#alert').append('<span style="font-family: system-ui;text-align:center">' + error_form + '</span>');
    setTimeout("$('#alert').css('display','none')", 4000);
  }

  ngOnInit() {

    if (this.entidadService.reservacion.pagado === '1') {
      this.router.navigate(['/reservacion-realizada']);
    }

    this.detalleForm = new FormGroup({
      terminos: new FormControl(true, [Validators.requiredTrue]),
      mediopago: new FormControl('seleccione', Validators.required)
    });

    // console.log(citamedica);



  }

  changeMedio(e) {
    this.spinner.show();
    if (e == 'izipay') {
      const params={'seguro':this.detalle};
      this.citamedicaService.temporalPacientesParameters({ citamedica: params }).subscribe((data) => {
      });

      if (this.entidadService.reservacion.izipayButton2 != 0) {
        this.entidadService.reservacion.izipayButton2 = 1;
        this.entidadService.saveReservacion();
        $("#button_pago").css('display', 'block')
        $("#btn_pagoefectivo").css('display', 'none')

        function loadScriptKR(src, llave, callback) {
          var script = document.createElement("script");
          script.setAttribute("src", src);
          script.setAttribute('kr-public-key', llave);
          script.setAttribute('id', 'payment_kr');
          script.addEventListener("load", callback);
          setTimeout(() => {
            document.head.appendChild(script);
          }, 1500);
        };

        this.sedeService.CrearFormularioIziPay(this.entidadService.reservacion.izipay)
          .subscribe((data: any) => {

            try {
              if (data.tokenstatus) {
                this.router.navigate(['/']);
                this.spinner.hide();
              }
            } catch (error) {

            }
            // console.log(data);

            $('#response_page').val('');
            $('#button_pago').empty();
            $('#payment_kr').remove();
            $('#css_izi').remove();
            $('#funciones_izi').remove();
            $('#krtoolbar').remove();
            setTimeout(() => {
              $('#button_pago').append('<div class="kr-embedded" kr-popin kr-form-token="' + data.answer.formToken + '" id="form_login">' +
                '<div id="alert" style="width:100%;display:none;color: #721c24 !important;background-color: #f8d7da !important;border-color: #f5c6cb !important;position: relative !important;padding: .75rem 1.25rem !important;margin-bottom: 1rem !important;border: 1px solid transparent !important;border-radius: .25rem !important;"></div> <div class="kr-pan"></div>' +
                '<div class="kr-expiry"></div>' +
                '<div class="kr-security-code"></div>' +
                '<button class="kr-payment-button"  ></button>' +
                ' <div class="customerror"></div>' +
                '</div>');
            }, 1500);


            var script_tag = document.createElement('script');
            var that = this;
            loadScriptKR('https://static.micuentaweb.pe/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js', this.entidadService.reservacion.llave_publica, function () {

              document.head.appendChild(script_tag);
              setTimeout("var script_tag2 = document.createElement('script');" +
                "script_tag2.setAttribute('src', './assets/src/funciones_izipay.js');" +
                "script_tag2.setAttribute('id', 'funciones_izi');" +
                "document.head.appendChild(script_tag2);", 2000);


              setTimeout(() => {
                $(".kr-popin-button").addClass("button")
                that.spinner.hide();
              }, 1000);
              that.spinner.hide();
            });



          }, error => {
            this.spinner.hide();
          });
      }
      else {
        $("#button_pago").css('display', 'block')
        $("#btn_pagoefectivo").css('display', 'none')
        this.spinner.hide();
      }

    } else {
      if (this.entidadService.reservacion.izipayButton2 == 1) {
        this.entidadService.reservacion.izipayButton2 = 0;
        this.entidadService.saveReservacion();
      }

      $("#button_pago").css('display', 'none')
      $("#btn_pagoefectivo").css('display', 'block')
      this.spinner.hide();
    }

  }

  onChange(deviceValue) {
    if (deviceValue == "No") {
      this.formSeguro = true;
      this.formSiSeguro = false;
      if (this.entidadService.usuario && this.entidadService.reservacion.hora) {
        const nombreCompleto = this.entidadService.usuario.name.split(' ');
        this.nombre = nombreCompleto[0];

        this.detalle = {
          paciente: this.entidadService.usuario.apellidos + ', ' + this.entidadService.usuario.name,
          sede: this.entidadService.reservacion.nombresede,
          medico: this.entidadService.reservacion.hora.nombre,
          fecha: this.entidadService.reservacion.hora.inicioms,
          hora: this.entidadService.reservacion.hora.inicioms,
          precioDet: ((this.entidadService.reservacion.cobertura.impCopagoFijo) / 100).toFixed(2)
        };
        // console.log(this.detalle)

      } else {
        // console.log('A');
        this.entidadService.reservacion.cita1 = 0;
        this.entidadService.reservacion.cita2 = 0;
        this.entidadService.saveReservacion();
        this.router.navigate(['/seleccione-sede']);
      }
    }
    else {
      this.formSiSeguro = true;
      this.formSeguro = false;

    }
  }

  createForm(): FormGroup {
    const idseguro = null;
    return new FormGroup({
      idseguro: new FormControl(null, Validators.required)
    });
  }
  createDetalleForm(): FormGroup {

    return new FormGroup({
      idseguro: new FormControl(null, Validators.required)
    });
  }

  confirmar_cita(): void {

    const datosPaciente = JSON.parse(sessionStorage.getItem('data'));
    var planseguro = this.entidadService.reservacion.seguroplan.split('-');
    const param = {
      idsede: parseInt(this.entidadService.reservacion.idsede, 10),
      namesede: this.entidadService.reservacion.nombresede,
      nombre: this.entidadService.usuario.name,
      apellidos: this.entidadService.usuario.apellidos,
      dni: datosPaciente.entidad.numerodoc,
      celular: datosPaciente.entidad.celular,
      idmedico: this.entidadService.reservacion.hora.idmedico,
      namemedico: this.entidadService.reservacion.hora.nombre,
      idpaciente: this.entidadService.usuario.id,
      fecha: this.entidadService.reservacion.fecha,
      inicio: this.entidadService.reservacion.hora.inicio,
      fin: this.entidadService.reservacion.hora.fin,
      idestado: 4, // pendiente
      idestadopago: 72, // pendiente
      idreferencia: 16, // Pagina web
      idatencion: 70, // Portal paciente
      email: this.entidadService.reservacion.izipay.email,
      precio: 0,  // cobertura al 100 %
      idaseguradoraplan: parseInt(planseguro[0]),
      seguro: this.entidadService.reservacion.seguro + " " + this.seguros[0][this.entidadService.reservacion.seguroplan],
      copagofijo: (this.entidadService.reservacion.izipay.precio / 100),
      codbeneficio: this.entidadService.reservacion.codbeneficio,
      deducible: this.entidadService.reservacion.deducible,
      codasegurado: this.entidadService.reservacion.codasegurado,
      poliza: this.entidadService.reservacion.poliza,
      sedeipress: this.entidadService.reservacion.sedeipress,
      orderid: this.entidadService.reservacion.orderid,
      bloqueo: 1
    };
    // console.log(param);
    this.spinner.show();
    this.citamedicaService.CreateCitamedicaCero({ citamedica: param }).subscribe((data) => {
      this.spinner.hide();

      if (data.type === 'success') {
        // console.log('array.data', data.data);
        this.entidadService.reservacion.pagado = '3';
        this.entidadService.reservacion.user_message = data.data.user_message;
        this.entidadService.saveReservacion();
        this.router.navigate(['/reservacion-realizada']);
      } else {

      }
    }, error => {
      this.spinner.hide();
      setTimeout(() => {
        this.errors = null;
      }, 10000);
    });
  }

  save_pagoefectivo(): void {

    const datosPaciente = JSON.parse(sessionStorage.getItem('data'));
    const seguroplan = this.entidadService.reservacion.seguroplan.split('-');
    const param = {
      idsede: parseInt(this.entidadService.reservacion.idsede, 10),
      pe_idservice: this.entidadService.reservacion.izipay.pe_idservice,
      pe_accesskey: this.entidadService.reservacion.izipay.pe_accesskey,
      pe_secretkey: this.entidadService.reservacion.izipay.pe_secretkey,
      namesede: this.entidadService.reservacion.nombresede,
      nombre: this.entidadService.usuario.name,
      apellidos: this.entidadService.usuario.apellidos,
      dni: datosPaciente.entidad.numerodoc,
      celular: datosPaciente.entidad.celular,
      idmedico: this.entidadService.reservacion.hora.idmedico,
      namemedico: this.entidadService.reservacion.hora.nombre,
      idpaciente: this.entidadService.usuario.id,
      fecha: this.entidadService.reservacion.fecha,
      inicio: this.entidadService.reservacion.hora.inicio,
      fin: this.entidadService.reservacion.hora.fin,
      idestado: 4, // pendiente
      idestadopago: 72, // pendiente
      idreferencia: 16, // Pagina web
      idatencion: 70, // Portal paciente
      email: this.entidadService.reservacion.izipay.email,
      precio: (this.entidadService.reservacion.izipay.precio / 100),
      orderid: this.entidadService.reservacion.orderid,
      bloqueo: 1,
      cobertura: this.entidadService.reservacion.cobertura,
      seguro: this.entidadService.reservacion.seguro + " " + this.seguros[0][this.entidadService.reservacion.seguroplan],
      copagofijo: (this.entidadService.reservacion.izipay.precio / 100),
      codbeneficio: this.entidadService.reservacion.codbeneficio,
      deducible: this.entidadService.reservacion.deducible,
      codasegurado: this.entidadService.reservacion.codasegurado,
      idaseguradoraplan: seguroplan[0],
      poliza: this.entidadService.reservacion.poliza,
      sedeipress: this.entidadService.reservacion.sedeipress,
      observaciones: "Reserva tu consulta - cobertura"
    };
    // console.log(param);
    this.spinner.show();
    this.citamedicaService.CreatePagarPe({ citamedica: param }).subscribe((data) => {
      this.spinner.hide();

      if (data.type === 'success') {
        // console.log('array.data', data.data);
        this.entidadService.reservacion.pagado = '2';
        this.entidadService.reservacion.user_message = data.data.user_message;
        this.entidadService.reservacion.filenamepdf = data.data.filenamepdf;
        this.entidadService.reservacion.cip = data.data.cip;
        this.entidadService.reservacion.cipurl = data.data.urlCip;
        this.entidadService.saveReservacion();
        this.router.navigate(['/reservacion-realizada']);
      } else {
        this.detallecitas.error=data.data;
        $("#modal_cita_appoiment").addClass('is-visible')

        this.errors = data.data;
        setTimeout(() => {
          this.errors = null;
        }, 10000);
      }
    }, error => {
      $("#modal_cita_appoiment").addClass('is-visible')
      this.detallecitas.error="Hubo un problema con los servicios intentar nuevamente.";
      this.spinner.hide();
      this.router.navigate(['/']);

      this.spinner.hide();
      setTimeout(() => {
        this.errors = null;
      }, 10000);
    });
  }

  checkTurnos(): void {
    const allInputs = $('.pick-turn-mod__dates input');
    $('.pick-turn-mod__dates').each(function () {
      $(this).find('button').each(function () {
        $(this).on('click', function (e) {
          e.preventDefault();
          allInputs.attr('checked', false);
          $(this).siblings().attr('checked', true);
        });
      });
    });
  }

  closeModal() {
    $("#modal").removeClass('is-visible')
    $("#overlay").removeClass('is-visible')
  }

  openPagar(): void {
    var val = $('#response_page').val();
    console.log("val", val)
    if (val != undefined) {
      var response_page = JSON.parse(val);

      this.entidadService.reservacion.pagado = '1';
      this.entidadService.reservacion.user_message = "";
      this.entidadService.reservacion.filenamepdf = "";
      this.entidadService.saveReservacion();
      this.next();

      this.save(response_page.clientAnswer.orderDetails.orderId, response_page.clientAnswer.transactions[0].transactionDetails.cardDetails.legacyTransId);
    }
  }
  modal_correo(): void {

    this.next();

  }
  next(): void {
    this.router.navigate(['/reservar-y-pagar']);
  }

  save(orderid: string, transaction: string): void {
    const seguroplan = this.entidadService.reservacion.seguroplan.split('-');
    const param = {
      idsede: parseInt(this.entidadService.reservacion.idsede, 10),
      idmedico: this.entidadService.reservacion.hora.idmedico,
      idpaciente: this.entidadService.usuario.id,
      fecha: this.entidadService.reservacion.fecha,
      inicio: this.entidadService.reservacion.hora.inicio,
      fin: this.entidadService.reservacion.hora.fin,
      idestado: 4, // pendiente
      idreferencia: 16, // Pagina web
      idatencion: 70, // Portal paciente
      email: this.entidadService.reservacion.izipay.email,
      orderid: orderid,
      idaseguradoraplan: seguroplan[0],
      copagofijo: (this.entidadService.reservacion.izipay.precio / 100),
      codbeneficio: this.entidadService.reservacion.codbeneficio,
      deducible: this.entidadService.reservacion.deducible,
      codasegurado: this.entidadService.reservacion.codasegurado,
      poliza: this.entidadService.reservacion.poliza,
      sedeipress: this.entidadService.reservacion.sedeipress,
      transaction: transaction
    };

    this.spinner.show();
    this.citamedicaService.CreatePagar({ citamedica: param }).subscribe((data) => {
      this.spinner.hide();

      if (data.type === 'success') {
        // console.log('array.data', data.data);
        this.entidadService.reservacion.pagado = '1';
        this.pagado = '1';
        this.entidadService.reservacion.user_message = data.data.user_message;
        this.entidadService.reservacion.filenamepdf = data.data.filenamepdf;
        this.entidadService.saveReservacion();
        this.router.navigate(['/reservacion-realizada']);
      } else {
        this.errors = data.data;
        setTimeout(() => {
          this.errors = null;
        }, 10000);
      }
    }, error => {
      this.spinner.hide();
      setTimeout(() => {
        this.errors = null;
      }, 10000);
    });

  }

}
